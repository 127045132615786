import { PDFViewer } from '@react-pdf/renderer'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import { sourceType } from '../../../../../enum/sourceType'
import { ComArticle } from '../../../../../models'
import PickingList from './PickingList'

const nonList: ComArticle[] = [
  {
    id: 'com-art_4f4cdf57-f69a-4221-a00a-413f0d61c039',
    //   created_at: 1695181836,
    //   updated_at: 1695181836,
    quantite: 1,
    statut: 'commercialisable',
    commande_id: 'comd_f55b5952-d093-487e-97e5-d8bcc410241b',
    libelle: 's1123121',
    conditionement_id: 'con_9a0d1f24-e4df-43e8-8c1d-41f33adf6c92',
    //   conditionnement: {
    //     id: "con_9a0d1f24-e4df-43e8-8c1d-41f33adf6c92",
    //     nom: "s3",
    //     icone: "/static/media/dropdown-vue.400a937f1c07efac9d01574f91465619.svg",
    //     niveau: 3,
    //     qte: 12,
    //     type: 1,
    //     poids_net: 192,
    //     poids_brut: 192,
    //     longueur: 4,
    //     largeur: 4,
    //     hauteur: 4,
    //     couche: 11,
    //     type_de_codebarre: "Autre",
    //     no_codebarre: "11231",
    //     rotation: "D",
    //     reference_id: "ref_cb931dac-3723-430a-8224-4c12927a0b1d",
    //     att_enable: true
    //   },
    reference_id: 'ref_cb931dac-3723-430a-8224-4c12927a0b1d',
    reference_number: 'QUYNH ANH TEA',
    status_code: StatusCommande.CreatedCommande,
    created_by: 'admin4-Madeleine Chat',
    creater_id: 'usr_a1ac5b89-924f-4960-818c-ee61557471af',
    sscc: '673849882303738296',
    total_pieses: 48,
    sous_qte: 12,
    dluo: 1695229200,
    volume_qte: 64,
    volume_sousqte: 64,
    poids_qte: 192,
    poids_sousqte: 16,
    source: sourceType.MANUAL,
    error_details:
      'Lot g21 with SSCC 390400000000000978 and Dluo 4850421120 was keep by other commande\n\nLot g21 with SSCC 390400000000001005 and Dluo 4850446438 was keep by other commande\n\nLot g21 with SSCC 390400000000001005 and Dluo 4850450379 was keep by other commande\n\nLot g21 with SSCC 390400000000000985 and Dluo 4850512369 was keep by other commande\n\nLot g21 with SSCC 390400000000000992 and Dluo 4850512918 was keep by other commande\n\nLot g21 with SSCC 390400000000001012 and Dluo 4850527942 was block on location\n\nLot g21 not enough on stock\n',
    sscc_block: [],
    sscc_motif: [],
    produits: [],
    //   pre_status: "79-0"
  },
]

export default function TestPdf() {
  return (
    <PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <PickingList title={'test title'} />
      {/* <PickingList commande={{com_article:[{description:'', total_pieses: 1}]}}/> */}
      {/* <NonMiseList commande={{}} nonList={generateLineFollowErrorDetail(nonList)}/> */}
      {/* <NonMiseList commande={{}} nonList={[]}/> */}
    </PDFViewer>
  )
}
